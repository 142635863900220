export const adminData = {
  eventFieldsDefault: [
    // {
    //   name: 'Zone name',
    //   name_wfh: 'Project',
    //   key: 'place_name'
    // },   
    {
      name: 'Cam name',
      name_wfh: 'Agent',
      key: 'cam_name'
    },
    {
      name: 'Event name',    
      name_wfh: 'Violation',
      key: 'name'
    },
    {
      name: 'Priority',
      key: 'priority'
    },
    {
      name: 'Image',
      key: 'image'
    }
  ],
  eventFields: [
    // {
    //   name: 'Zone ID',
    //   name_wfh: 'Project ID',
    //   key: 'placeId'
    // },
    {
      name: 'Zone name',
      name_wfh: 'Project',
      key: 'place_name'
    },
    {
      name: 'Cam ID',
      name_wfh: 'Agent ID',
      key: 'camId'
    },
    {
      name: 'Cam name',
      name_wfh: 'Agent',
      key: 'cam_name'
    },
    {
      name: 'Event time',
      name_wfh: 'Event Date Time',
      key: 'event_time'
    },
    {
      name: 'Name',
      name_wfh: 'Violation',
      key: 'name'
    },
    {
      name: 'Priority',
      key: 'priority'
    },
    {
      name: 'Type',
      name_wfh: 'Violation Type',
      key: 'type'
    },
    {
      name: 'Tags',
      key: 'tags'
    },
    {
      name: 'Image',
      key: 'image'
    }
  ],
  eventFields2: [
    {
      name: 'Zone ID',
      name_wfh: 'Project ID',
      key: 'placeId'
    },
    // {
    //   name: 'Zone name',
    //   name_wfh: 'Project',
    //   key: 'place_name'
    // },
    {
      name: 'Cam ID',
      name_wfh: 'Agent ID',
      key: 'camId'
    },
    {
      name: 'Cam name',
      name_wfh: 'Agent',
      key: 'cam_name'
    },
    {
      name: 'Event time',
      name_wfh: 'Event Date Time',
      key: 'event_time'
    },
    {
      name: 'Name',
      name_wfh: 'Violation',
      key: 'name'
    },
    {
      name: 'Priority',
      key: 'priority'
    },
    {
      name: 'Type',
      name_wfh: 'Violation Type',
      key: 'type'
    },
    {
      name: 'Tags',
      key: 'tags'
    },
    {
      name: 'Image',
      key: 'image'
    }
  ],
  eventFields3: [
    // {
    //   name: 'Zone ID',
    //   name_wfh: 'Project ID',
    //   key: 'placeId'
    // },
    // {
    //   name: 'Zone name',
    //   name_wfh: 'Project',
    //   key: 'place_name'
    // },
    {
      name: 'Cam ID',
      name_wfh: 'Agent ID',
      key: 'camId'
    },
    {
      name: 'Cam name',
      name_wfh: 'Agent',
      key: 'cam_name'
    },
    {
      name: 'Event time',
      name_wfh: 'Event Date Time',
      key: 'event_time'
    },
    {
      name: 'Name',
      name_wfh: 'Violation',
      key: 'name'
    },
    {
      name: 'Priority',
      key: 'priority'
    },
    {
      name: 'Type',
      name_wfh: 'Violation Type',
      key: 'type'
    },
    {
      name: 'Tags',
      key: 'tags'
    },
    {
      name: 'Image',
      key: 'image'
    }
  ],
  eventPriorities: [
    {
      name: 'Very low',
      keyLabel: 0,
    },
    {
      name: 'Low',
      keyLabel: 1,
    },
    {
      name: 'Medium',
      keyLabel: 2,
    },
    {
      name: 'High',
      keyLabel: 3,
    },
  ],
  eventImages: [
    {
      name: 'Primary Image',
      key: 'primaryImage',
    },
    {
      name: 'Secondary Image',
      key: 'secondaryImage'
    },
  ],
  priority: [
    'Very low','Low','Medium','High'
  ],
  priorityValues: [
    {
      label: 'VERY LOW',
      value: 0,
      color: '#50E3C2'
    },{
      label: 'LOW',
      value: 1,
      color: '#F8E71C'
    },
    {
      label: 'MEDIUM',
      value: 2,
      color: '#F5A623'
    },
    {
      label: 'HIGH',
      value: 3,
      color: '#B04457'
    }
  ],
  eventData: [
    {
      label: 'Very low and above',
      value: 0
    },
    {
      label: 'Low and above',
      value: 1
    },
    {
      label: 'Medium and above',
      value: 2
    },
    {
      label: 'High and above',
      value: 3
    },
  ],
  role: [
    {
      name: 'ADMIN',
    },
    {
      name: 'REGULAR',
    },
    {
      name: 'RESIDENT',
    },
  ],
  parking: [
    {
      vehicleNumber: 'MH 12 DE 3356',
      passType: 'Employee',
      entryImg: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Niyamasabha.jpg',
      entryDate: '12/04/19',
      entryTime: '14:44',
      exitImg: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Niyamasabha.jpg',
      exitDate: '12/04/19',
      exitTime: '14:55',
      duration: '22:02 hrs',
      vehicleType: 'Sedan',
      vehicleColor: 'Black'
    },
    {
      vehicleNumber: 'MH 12 DE 3356',
      passType: 'Employee',
      entryImg: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Niyamasabha.jpg',
      entryDate: '12/04/19',
      entryTime: '14:44',
      exitImg: 'https://res.cloudinary.com/duv7nq4ge/image/upload/v1539118364/1_zuzivw.jpg',
      exitDate: '12/04/19',
      exitTime: '14:55',
      duration: '22:02 hrs',
      vehicleType: 'Sedan',
      vehicleColor: 'Red'
    },
    {
      vehicleNumber: 'MH 12 DE 3356',
      passType: 'Guest',
      entryImg: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Niyamasabha.jpg',
      entryDate: '12/04/19',
      entryTime: '14:44',
      exitImg: '',
      exitDate: '12/04/19',
      exitTime: '14:55',
      duration: '22:02 hrs',
      vehicleType: 'Sedan',
      vehicleColor: 'White'
    },
  ],
  statParking: [
    {
      title: 'VEHICLES ENTERED',
      text: 234,
    },
    {
      title: 'VEHICLES EXITED',
      text: 112,
      subText: 'HRS'
    },
    {
      title: 'VEHICLES IN PARKING',
      text: 554,
      subText: 600
    },
  ],
  userNotificationFields: [
    {
      name: 'User Name',
      key: 'userName'
    },
    {
      name: 'Type',
      key: 'type'
    },
    {
      name: 'User Image',
      key: 'userImage'
    },
    {
      name: 'Menu',
      key: 'menu'
    }
  ],
  violationFieldsDefault: [
    // {
    //   name: 'Zone name',
    //   name_wfh: 'Project',
    //   key: 'place_name'
    // },   
    {
      name: 'Cam name',
      name_wfh: 'Agent',
      key: 'cam_name'
    },
    {
      name: 'Event name',
      name_wfh: 'Violation',
      key: 'name'
    },
    {
      name: 'Date',
      key: 'event_time'
    },
    {
      name: 'Image',
      key: 'image'
    },
    {
      name: 'Action For Violation',
      key: 'actionForViolation'
    },
    {
      name: 'Reason For Violation',
      key: 'reasonForViolation'
    },
    {
      name: 'Confirmation',
      key: 'confirmation'
    },
    {
      name: 'Purge',
      key: 'purge'
    }
  ],
}