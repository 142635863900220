/* eslint-disable */ 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, colors } from '@material-ui/core';
import { Controller } from '../common/Controller';
import { Breadcrumbs } from '../components/Breadcrumbs'
import { AppHelper } from '../helpers/AppHelper';
import { ViewSummaryButton } from '../screens/ClientPlace/ViewSummaryButton';
import { SingleSelect } from './SingleSelect';
import { AppConfig } from '../AppConfig';
import { Styles } from './Styles'
import { CommonStyles } from '../styles/Styles';
import { CheckboxComponent } from '../components/CheckboxComponent';
import { StyleSheet, css } from 'aphrodite';
import { SelectComponentV2 } from './SelectComponentV2';
import { SearchTextField } from '../screens/ClientPlace/SearchTextField';

export class ActionHeaderbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camFilterStatus:sessionStorage.getItem("camFilterStatus")?sessionStorage.getItem("camFilterStatus"):"default",
      searchText: '',
    }
  }

  componentDidMount() { }

  sliderFunction = (e) => {
    let value = 0;
    if (e === 0) {
      value = 12
    } else if (e === 1) {
      value = 6
    } else if (e === 2) {
      value = 4
    } else if (e === 3) {
      value = 3
    } else {
      value = 2
    }

    this.props.setColNumber(value)
  }

  slider = () => {
    return (
      <div className={css(Styles.formControlSwitch)}>
        <Slider
          min={0}
          max={4}
          defaultValue={2}
          step={1}
          className={css(Styles.sliderContainer)}
          railStyle={{ backgroundColor: '#3F4450', height: 4, opacity: .38 }}
          trackStyle={{ backgroundColor: '#3F4450', height: 4, opacity: 1 }}
          handleStyle={{
            borderColor: '#fafafa',
            height: 11,
            width: 11,
            marginLeft: 0,
            marginTop: -3,
            backgroundColor: '#fafafa',
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
          }}
          onChange={this.sliderFunction}
        />
      </div>
    )
  }

  renderPicker = () => {
    if (this.props.showPicker && this.props.pickerItem) {
      return (
        <SingleSelect
          formField={this.props.pickerItem}
          value={this.props.pickerItem.value}
          setValue={this.props.onPickerSelect}
          disabled={false}
          singlePickerForm={Styles.pickerInnerContainer}
        />
      )
    }
  }

  handleAlignment = (e, newAlignment, key)=>{
    // if(!newAlignment) return;
    this.setState({
      camFilterStatus:e
    })
    this.props.setCamFilterStatus(e)
  }

  renderToggle = () => {
    return (
      <div>
                {/* <CheckboxComponent
          disableMultiple={true}
          headerView={true}
          formField={{
            key: 'camFilterStatus',
            label: '',
            labelValue: '',
            type: 'checkbox',
            options: [
              {
                key: 'default',
                name: 'All'
              },
              {
                key: 'Active',
                name: 'Active Cams'
              },
              {
                key: 'InActive',
                name: 'Inactive Cams'
              }]
          }}
          value={this.state.camFilterStatus}
          disabled={false}
          setValue={this.handleAlignment}
        /> */}
         {/* <SelectComponentV2
          key={formField.key}
          formField={formField}
          value={this.responseByKey[formField.key]}
          setValue={this.setValue}
          enableValidation={this.props.enableValidation}
          disabled={this.props.formDisabled || formField.disabled}
          action={this.props.action}
        /> */}
        <SingleSelect
          // disableMultiple={true}
          // headerView={true}
          color={"#fff"}
          key={"camFilterStatus"}

          formField={{
            key: 'camFilterStatus',
            label: '',
            labelValue: '',
            // type: 'checkbox',
            options: [
              {
                key: 'default',
                name: 'All',
                _id: 'default'
              },
              {
                key: 'Active',
                name: 'Active Cams',
                _id: 'Active'
              },
              {
                key: 'InActive',
                name: 'Inactive Cams',
                _id: 'InActive'
              }]
          }}
          value={this.state.camFilterStatus}
          disabled={false}
          setValue={this.handleAlignment}
        />
      </div>
    );
  }
  
  setTitleContainerStyle = () => {
    if (this.props.showSummaryButton) {
      return css(CommonStyles.headerOrgOuterContainer, Styles.headerOrgOuterContainer, Styles.headerOrgOuterContainerSpec)
    } else {
      return css(CommonStyles.headerOrgOuterContainer, Styles.headerOrgOuterContainer)
    }
  }

  render() {
    let latestEventImageUrl = this.props.latestEventImageUrl ? this.props.latestEventImageUrl : ""
    latestEventImageUrl = latestEventImageUrl.split("?")[0]
    return (
      <div className={css(CommonStyles.outerHeaderContainer,   this.props.fullScreenMode == "EXIT FULLSCREEN" ? CommonStyles.headerFixedTop : '')}>
        <div className={css(CommonStyles.innerHeaderContainer)}>
          <div className={css(CommonStyles.innerHeaderFirstContainer)}>

            {this.props.backPathName &&
              <Link
                to={{ pathname: this.props.backPathName }}
                className={css(Styles.link)}>
                <div className={css(CommonStyles.headerMemberAddContainer, CommonStyles.backButton)} >
                  <img src={require('../assets/images/back-img.svg')} className={css(CommonStyles.addImg)} />
                  <p className={css(CommonStyles.margin)}>BACK</p>
                </div>
              </Link>
            }

            {this.renderPicker()}

            {this.props.camLiveIndicator && window.innerWidth > 768 &&
              <div className={css(Styles.liveTitleContainer)}>
                <p className={css(CommonStyles.paragraphMargin)}>LIVE</p>
                <div className={css(Styles.liveTitleDotContainer)} />
              </div>
            }

            <div className={css(Styles.titleAndSummaryStyle)}>
              <div className={this.setTitleContainerStyle()}>
                <p className={css(CommonStyles.headerMemberCountContainer, CommonStyles.bgWhite)}>{this.props.title}</p>
                {this.props.breadcrumbs &&
                  <Breadcrumbs items={this.props.breadcrumbs} />
                }
              </div>

              {(this.props.showSettingsIcon && Controller.get().userMgr().isAdminUser()) && AppHelper.showSettings(this.props.org, 'org') &&
                <Tooltip title={'SETTINGS'}>
                  <div
                    aria-describedby={this.props.id}
                    onClick={(e) => this.props.onSettingClick(e)}
                    className={css(Styles.editContent, Styles.editContentSettings)}>
                    <Icon className={css(Styles.settingsIcon)}>settings</Icon>
                  </div>
                </Tooltip>
              }

              {this.props.showSummaryButton &&
                <ViewSummaryButton
                  actionHeaderbar={true}
                  showStats={this.props.showStats}
                  showStatsHandler={this.props.showStatsHandler}
                />
              }
            </div>
            {AppConfig.camFilterStatusEnabled && this.props.showCamFilter && this.renderToggle()}

          </div>

          <div className={css(CommonStyles.headerMemberOuterContainer)}>
            {this.props.applySearch && 
              <div className={css(Styles.paginationContainer)}>
                <div className={css(Styles.innerSearchContainer)}>
                  <SearchTextField
                    placeholder="Search Cameras"
                    searchHandler={this.props.applySearch}
                    disableSearchIcon={true}
                  />
                </div>
              </div>
            }
            {this.props.camFilterCount && 
              <div className={css(CommonStyles.bgWhite, CommonStyles.headerMemberStyle, CommonStyles.headerMemberMobileStyle)} >
                {this.props.camFilterCount}
              </div>
            }
            
            {/* {this.props.fullScreen &&
              <div className={css(CommonStyles.headerMemberAddContainer, CommonStyles.headerMemberStyle, CommonStyles.headerMemberMobileStyle)}
                onClick={() => this.props.setFullscreen()}>
                <img src={require('../assets/images/fullscreen-img.svg')} className={css(CommonStyles.addImg)} />
                {this.props.fullScreenMode}
              </div>
            } */}

            {this.props.slider &&
              <div className={css(CommonStyles.headerMemberAddContainer, CommonStyles.headerMemberStyle, CommonStyles.headerMemberMobileStyle)} >
                <img src={require('../assets/images/grid-img2.svg')} className={css(CommonStyles.addImg, Styles.gridImg)} />
                {this.slider()}
                <img src={require('../assets/images/grid-img1.svg')} className={css(CommonStyles.addImg, Styles.gridImg)} />
              </div>
            }

            {this.props.statSuffix && !this.props.items &&
              <div className={css(CommonStyles.headerMemberOuterMobileStyle)}>
                <p className={css(CommonStyles.headerMemberCountContainer, CommonStyles.bgWhite)}>{this.props.statSuffix}</p>
              </div>
            }

            {this.props.statPrefix && !this.props.statSuffix &&
              <div className={css(CommonStyles.headerMemberOuterMobileStyle)}>
                <p className={css(CommonStyles.headerMemberCountContainer, CommonStyles.bgWhite)}>{this.props.items.length} {this.props.statPrefix}</p>
              </div>
            }
            {this.props.camType && this.props.camType == 'alert' && AppConfig.ENABLE_ADVANCE_SETTINGS && 
              <Link
                to={AppConfig.advancedShieldSettingUrl + "?url=" +latestEventImageUrl+"&camId="+this.props.camId} 
                target="_blank"
                className={css(Styles.link)}>
                <div className={css(CommonStyles.headerMemberAddContainer)} >
                <Icon className={css(Styles.advancedSettingsIcon, CommonStyles.addIcon)}>settings</Icon> 
                  <p className={css(CommonStyles.margin)}>ADVANCE SETTINGS</p>
                </div>

              </Link>
            }
            {(Controller.get().userMgr().isProductUser() ) && this.props.buttonOneTitle &&
              <div className={css(CommonStyles.headerMemberAddContainer)} onClick={() => this.props.buttonOneAction()}>
                <img src={require('../assets/images/add-img.svg')} className={css(CommonStyles.addIcon)} />
                <p className={css(CommonStyles.margin)}>{this.props.buttonOneTitle}</p>
              </div>
            }

            {/* {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser() || Controller.get().userMgr().isResidentUser()) && this.props.buttonTwoTitle && !this.props.camPage &&
              <div className={css(CommonStyles.headerMemberAddContainer)} style={{marginLeft : '10px'}} onClick={() => this.props.buttonTwoAction()}>
                <img src={require('../assets/images/add-img.svg')} className={css(CommonStyles.addIcon)} />
                <p className={css(CommonStyles.margin)}>{this.props.buttonTwoTitle}</p>
              </div>
            } */}

            {/* {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser() || Controller.get().userMgr().isResidentUser()) && this.props.buttonTwoTitle && this.props.camPage &&
              <div className={css(CommonStyles.headerMemberOuterMobileStyle)}>
                <Button
                  disabled={this.props.inProcess}
                  variant='contained'
                  onClick={e => this.props.buttonTwoAction()}
                  className={css(Styles.buttonStyle, Styles.buttonStyleUpload)}>
                  {this.props.buttonTwoTitle}
                </Button>
              </div>
            } */}

            {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser() || Controller.get().userMgr().isResidentUser()) && this.props.buttonThreeTitle &&
              <div className={css(CommonStyles.headerMemberAddContainer)} style={{marginLeft : '10px'}} onClick={() => this.props.buttonThreeAction()}>
                <img src={require('../assets/images/add-img.svg')} className={css(CommonStyles.addIcon)} />
                <p className={css(CommonStyles.margin)}>{this.props.buttonThreeTitle}</p>
              </div>
            }

            {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser() || Controller.get().userMgr().isResidentUser()) && this.props.auditButtonTitle &&
              <div className={css(CommonStyles.headerMemberAddContainer, CommonStyles.marginLeft)} onClick={() => this.props.auditButtonTitleAction()}>
                <img src={require('../assets/images/download-img.svg')} className={css(CommonStyles.addIcon)} />
                <p className={css(CommonStyles.margin)}>{this.props.auditButtonTitle}</p>
              </div>
            }
            
            {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser()) && this.props.buttonFourTitle && window.innerWidth > 768 &&
              <div className={css(CommonStyles.headerMemberOuterMobileStyle)}>
                <Button
                  disabled={this.props.inProcess}
                  variant='contained'
                  onClick={e => this.props.buttonFourAction()}
                  className={css(Styles.buttonStyle, Styles.buttonStyleUpload)}>
                  {this.props.buttonFourTitle}
                </Button>
              </div>
            }
          </div>
        </div>
      </div >
    )
  }

}

const Style = StyleSheet.create({
  formOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: AppTheme.primaryBackground,
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0
  },
  formInnerContainer: {
    display: 'flex',
    flex: 1,
  },
})