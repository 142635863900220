/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Link } from 'react-router-dom';
import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { AppHelper } from '../../helpers/AppHelper';
import { Controller } from '../../common/Controller';
import { settings, relativesettings, relativesettingsLess, relativeImageSliderSettings } from './SliderSettings';
import { SearchTextField } from './SearchTextField';
import { AppConfig } from '../../AppConfig';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import async from 'async';
import CircularProgress from '@material-ui/core/CircularProgress';

import { appData } from '../../data/AppData';
import { CustomFormFieldsDialogV2 } from '../../components/CustomFormFieldsDialogV2';
import { Helper } from '../../common/helpers/Helper';

import ReactPlayer from 'react-player'
import { AppTheme } from '../../styles/AppTheme';


var faceMap = {}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef()    
    this.imageRef = React.createRef()    

    this.selectedEventInfo = this.props.selectedEditItem
    this.isEventDetailsPageEnabled = false	    
    if(!this.props.selectedEditItem && this.props.selectedEvent){	
      this.selectedEventInfo = this.props.selectedEvent	
      this.isEventDetailsPageEnabled = true	
    }	         
    
    this.state = {
      player: 'play_arrow',
      selectedImageIndex: 0,
      selectedRelatedEventIndex: 0,
      comment:"",
      commentType:"",
      seniorManagerReview:this.selectedEventInfo && this.selectedEventInfo.latestReviewsBySeniorManager && this.selectedEventInfo.latestReviewsBySeniorManager[0] ?this.selectedEventInfo.latestReviewsBySeniorManager[0] : [] ,
      managerReview:this.selectedEventInfo && this.selectedEventInfo.latestReviewsByManager && this.selectedEventInfo.latestReviewsByManager[0] ? this.selectedEventInfo.latestReviewsByManager[0] :[],
      selectedEditItem:this.selectedEventInfo,
      openManagerReview:false,
      openSeniorManagerReview:false,
      eventInfoImgeHeight : 0,
      eventInfoImgeWidth : 0,
      showCanvas : false,
      imageInfo:"",
      fetchState: ApiHelper.State.LOADING,
      showWFH:true,	
      showOffice:false,
      userNotificationList:[],
      showUserNotificationDialog:false,
      dialogTitle:"User",
      anchorEl:null,
      selectedItem:null,
      dialogAction:'',
      confirmDeleteView:false,
      faceList:[],
      fetchStateForFR:ApiHelper.State.READY,
      openRelatedImages:false,
      showVideo:false,
      isVideoReady:false,
      videoError:false
    }
    this.pause = true;
    this.play = false;
    this.playerFnId = null;
    this.reviews = []
    this.openCommentsModal = this.openCommentsModal.bind(this)
  }

  getDetailsFromNameInFR = () =>{
    let faceList  = this.state.faceList
    for (let index = 0; index < faceList.length; index++) {
      const element = faceList[index];
      if (element && element.name &&  this.responseByKey &&  this.responseByKey['name'] && element.name.trim().toLowerCase() == this.responseByKey['name'].trim().toLowerCase() && this.responseByKey['_id']  == element._id) {
          // this.responseByKey['_id']  = element._id
          this.responseByKey['image']  = this.responseByKey['image'].concat(element.image)
          this.responseByKey['updatedAt']  = element.updatedAt
          this.responseByKey['createdAt']  = element.createdAt
          this.responseByKey['__v']  = element.__v

       }
    }
  }

  saveFace = () => {
    const thisObj = this;
    let camId = thisObj.props.camId
    let placeId = this.props.placeId
    let options = {};
    let data = thisObj.responseByKey
    
    data['camId'] = camId
    data['camIds'] = [camId]

    data['placeId'] = placeId
    if (data['alertEnabled'] != undefined) {
      delete data['alertEnabled']
    }
    if (data['_id'] || data['_id'] == "") {
      delete data['_id']
    }
    if(this.validateFace(data)){
    options = {
      method: "POST",
      endPoint: ApiHelper.makeUrlPath(["faces"]),
      jsonBody: { faceData: data }
    };
    ApiHelper.call(
      options,
      function (err, data) {
        if (err) {
          this.setState({
            errMsg: Helper.getErrorMsg(err),
            inProcess: false,
          })
          return;
        } else {
          this.resetToDefaultValues()
          this.fetchNotificationUsers(true)
        }
      }.bind(this)
    );
  }
  };

  validateFace = (data) => {
    if (!data.name) {
      this.setState({
        errMsg: "Please enter name",
        inProcess: false,
      })
      return false

    } else if (!data.type) {
      this.setState({
        errMsg: "Please enter type",
        inProcess: false,
      })
      return false

    } else if (!data.image || data.image.length == 0) {
      this.setState({
        errMsg: "Please upload images",
        inProcess: false,
      })
      return false

    }
    return true
  }

  updateFace = (obj) => {
    const id = obj._id
    if (!id) {
      return
    }
    let camId = this.props.camId

    let placeId = this.props.placeId
    let options = {};
    let data = this.responseByKey
    data['camId'] = camId
    data['camIds'] = [camId]

    data['placeId'] = placeId
    if (data['alertEnabled'] != undefined) {
      delete data['alertEnabled']
    }
    if (this.validateFace(data)) {

      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['faces', id]),
        jsonBody: data
      }

      Helper.removeNullValues(options.jsonBody)

      ApiHelper.call(
        options,
        function (err, data) {
          if (err) {
            this.setState({
              errMsg: Helper.getErrorMsg(err),
              inProcess: false,
            })
            return;
          } else {
            this.resetToDefaultValues()
            this.fetchNotificationUsers(true)
          }
        }.bind(this)
      );
    }
  };

  addFace = (obj) => {
    if (this.validateFace(obj) == false) {return false }
      this.getDetailsFromNameInFR()
      if (obj['allImages']) {
        delete obj['allImages']
      }
      this.setState({
        inProcess: true
      })

      if (obj._id) {
        this.updateFace(obj)
      } else {
        this.saveFace()
      }
  }


  deleteUser = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    this.setState({
      inProcess: true
    })

    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['faces', id]),
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchNotificationUsers()
      }
    }.bind(this))
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
    });
  };

  showDeleteUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Delete a user',
      dialogAction: 'delete',
      errMsg:""
    })
  }

  showUpdateUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Edit a user',
      dialogAction: 'edit',
    })
  }

  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}

  if(this.props.selectedEvent.fr != undefined){
    delete this.props.selectedEvent['fr']
  }    

    this.setState({
      showUserNotificationDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null
    })
  }
  
  
  showAddUserNotificationDIalog = () =>{
    this.fetchNotificationUsers(false, ()=>{
    this.setState({
      showUserNotificationDialog : true,
      dialogTitle:"User",
      dialogAction:'add'
    })
  })

  }
  setAutoCompleteValues=(key, val) =>{
    if( !this.props.selectedEvent.fr){
      this.props.selectedEvent['fr'] = {}
    }
    this.props.selectedEvent.fr[key+'2'] = val
    this.setState({})
  }
  renderUserNotificationDialog = () => {
    const formFields = appData.eventNotificationFields
    this.responseByKey = {}
    this.responseByKey['allImages']  = this.props && this.props.selectedEvent &&  this.props.selectedEvent.additionalImages && this.props.selectedEvent.additionalImages.length > 0 ?JSON.parse(JSON.stringify( this.props.selectedEvent.additionalImages)): ""
    this.responseByKey['type']  = this.props && this.props.selectedEvent && this.props.selectedEvent.fr && this.props.selectedEvent.fr.type2 ? this.props.selectedEvent.fr.type2: ""
    this.responseByKey['name']  = this.props && this.props.selectedEvent && this.props.selectedEvent.fr && this.props.selectedEvent.fr.name2 ? this.props.selectedEvent.fr.name2: ""
    this.responseByKey['_id']  = this.props && this.props.selectedEvent && this.props.selectedEvent.fr && this.props.selectedEvent.fr._id2 ? this.props.selectedEvent.fr._id2: ""
    this.responseByKey['image']  = this.props && this.props.selectedEvent && this.props.selectedEvent.fr && this.props.selectedEvent.fr.selectedImage2 ?[this.props.selectedEvent.fr.selectedImage2.split("?")[0]]: ""

    return (
      <CustomFormFieldsDialogV2
        enableFR={this.props.enableFR || this.selectedEventInfo}
        setAutoCompleteValues = {(k,v)=>this.setAutoCompleteValues(k,v)}
        autoCompleteData={this.state.faceList}
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.showUserNotificationDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        action={this.state.dialogAction}
        formFields={formFields}
        showConfirmDeleteView={this.showConfirmDeleteView}
        multi={true}
        deleteItem={() => this.deleteUser()}
        responseByKey={this.responseByKey}
        errMsg={this.state.errMsg}
        onSuccess={this.addFace}
      >
      </CustomFormFieldsDialogV2>
    )
  }



  searchBox = () => {
    return (
      <SearchTextField
        searchHandler={this.props.searchHandler} />
    )
  }

  showRelatedImages = (index) => {
    this.setState({
      selectedImageIndex: index,
      relatedEvent: false
    })
    this.props.imageSliderHandler(true)
  }

  closeRelatedImages = () => {
    this.setState({ selectedImageIndex: 0 })
    this.props.imageSliderHandler(false)
  }

  showRelatedEvents = (index, item) => {
    this.setState({
      relatedEvent: true,
      selectedRelatedEventIndex: index,
    })
    this.props.imageSliderHandler(false)
    this.props.onRelatedEventTileClick(item)
  }

  closeRelatedEvent = () => {
    this.setState({ relatedEvent: false, selectedRelatedEventIndex: 0 })
  }

  renderEventHeader = (item) => {
    return (
      <div className={this.props.relativeImageSliderMode ? css(Styles.eventItemHeaderConatinerRelativeOpen) : css(Styles.eventItemHeaderConatinerRelativeClose)}>
        <div className={css(Styles.eventHeaderOuterContent)}>
          <div className={css(Styles.eventItemHeaderInnerContent)}>

            {this.props.searchHandler &&
              <div className={css(Styles.eventItemHeaderSearchInnerContent)}>
                {this.searchBox()}
              </div>
            }

            {item &&
              <p className={css(Styles.eventItemTime)}>{moment(item.event_time).format('MMM DD, YYYY, h:mm:ss A')}</p>
            }

            {item &&
              <p className={css(Styles.eventItemTitle)}>{item.name}</p>
            }

            {item &&
              <div className={css(Styles.eventItemInnerContent)}>
                <div className={css(Styles.eventItemOuterTagContent)}>

                  <div className={css(Styles.eventItemInnerTagContent)}>
                    {item.camName &&
                      <div className={css(Styles.eventItemType)}>{item.camName}</div>
                    }

                    {item.type &&
                      <div className={css(Styles.eventItemType)}>{item.type}</div>
                    }

                    <div className={css(Styles.eventItemType, Styles.eventItemTypePriority)}>
                      {AppHelper.getPriorityStr(item.priority)}
                    </div>

                  </div>
                </div>
                {item && item.tags &&
                  <div className={css(Styles.eventEditContainer, Styles.eventEditContainerStyle, Styles.tagContainerHeight)}>
                    {Object.keys(item.tags).map((key, i) => {
                      return (
                        key != 'faces' ? <div key={i} className={css(Styles.tagContainer)}>
                          <p className={css(Styles.tagKey)}>{key}</p>
                          <p className={css(Styles.tagValue)}>{item.tags[key] + " "}</p>
                        </div>:<></>
                      )
                    })}
                  </div>
                }

              </div>
            }
            {
              item && 
              item &&
              <div className={css(Styles.eventItemInnerContent)}>
                <div className={css(Styles.eventItemOuterTagContent)}>
              {
                <Button
                  variant='contained'
                  onClick={() => this.props.applyGenAI(item)}
                  className={css(Styles.buttonStyle2, Styles.ml0)}
                >
                 Try generative AI
                </Button>
              }
                    {!item.genAI && <span style={{ marginLeft: "5px" }}> {this.props.selectedCam && this.props.selectedCam.settings && this.props.selectedCam.settings.genAISettings && this.props.selectedCam.settings.genAISettings.query ? this.props.selectedCam.settings.genAISettings.query.replace("Only answer Yes or No", "") : ''}{this.props.selectedCam && this.props.selectedCam.settings && this.props.selectedCam.settings.genAISettings && this.props.selectedCam.settings.genAISettings.query && item.genAI ? " : " : ""}{item.genAI ? item.genAI : ""}</span>}
                    <span style={{marginLeft:"5px"}}> { item && item.genAI && item.genAI.query ?  item.genAI.query.replace("Only answer Yes or No", "") + " : " + item.genAI.response : ""}</span>
            </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }

  processImages = (item) => {
    let eventImages = [item.image]
    if (item.additionalImages && item.additionalImages.length > 0) {
      eventImages = eventImages.concat(item.additionalImages)
    }
    return eventImages
  }

  renderRelativeImageSlider = (item) => {
    if (!item) return

    const eventImages = this.processImages(item)
    if (this.props.relativeImageSliderMode) {
      return (
        <div className={css(Styles.imageSliderContainer)}>
          {this.props.relativeImageSliderMode &&
            <div className={css(Styles.eventLiveFeed)} onClick={() => this.closeRelatedImages()}>
              <img src={require('../../assets/images/close-img-white.svg')} className={css(CommonStyles.addImg)} />
            </div>
          }

          <Slider
            {...relativeImageSliderSettings}
            className={css(Styles.slider)}
            initialSlide={this.state.selectedImageIndex} >
            {eventImages.length > 0 &&
              eventImages.map((option, i) => {
                return (
                  <div key={i} className={css(Styles.eventImageSpecContainer)}>
                    <img
                      src={option}
                      key={option}
                      className={css(Styles.eventImageSpec)} />
                  </div>
                )
              })}
          </Slider>
        </div>
      )
    }
  }


  onPlay = () => {
    if (this.play) {
      this.play = false;
      this.pause = true;
      this.setState({
        player: 'play_arrow'
      })
      if (this.playerFnId) {
        clearInterval(this.playerFnId);
      }
      this.playerFnId = null
    } else {
      this.pause = false;
      this.play = true;
      this.setState({
        player: 'pause'
      })
      this.playerFnId = setInterval(() => {
        this.playEvent();
      }, AppConfig.jpegPlayerRefreshInterval);
    }
  }

  playEvent = () => {
    if (this.props.eventIndex === (this.props.eventList.length - 1)) {
      if (this.play) {
        this.onPlay()
      }
      return;
    }
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex + 1], this.props.eventIndex + 1)
  }

  eventNext = () => {
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex + 1], this.props.eventIndex + 1)
  }

  eventBefore = () => {
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex - 1], this.props.eventIndex - 1)
  }

  handleClickOpen = () => {
    this.setState({
    openCommentsModal :true
    })  
  };

  handleClose = () => {
   this.setState(
     {
       openCommentsModal: false,
     },
     () => {
       this.setState({
         commentType: "",
         comment: "",
       });
     }
   );
  };

  fetchNotificationUsers = (doupdate, callback) => {
    if(this.state.faceList && this.state.faceList.length > 0 && !doupdate){
      if(callback)return callback()
      return
    }
    this.setState({
      fetchStateForFR: ApiHelper.State.LOADING,
    })
    async.parallel(
      [
         (callback) => {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['faces'], { camId: this.selectedEventInfo && this.selectedEventInfo.camId ? this.selectedEventInfo.camId:"" }) }, callback)
        },
      ],
      function (err, faces) {
        faces = faces[0]
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        // for (let index = 0; index < faces.length; index++) {
        //   const element = faces[index];
        //   faceMap[element._id] = element.name
        // }
        if(this.props.updateFaceData && doupdate){  
          this.props.updateFaceData()
        }
        this.setState({
          faceList: faces,
          fetchStateForFR: ApiHelper.State.READY,
        },()=>{
          if(callback){
            return callback()
          }
        })
      }.bind(this))
  }
 

saveComments = () =>{
  if (this.state.commentType) {
    let org = Controller.get().userMgr().getCurrentOrg();

    let tags = {};
    if (this.selectedEventInfo.tags) {
      tags = this.selectedEventInfo.tags;
    }

    if (org.managerType == "PROJECT_MANAGER") {
      tags["project_manager_review"] = this.state.commentType;
    } else {
      tags["org_manager_review"] = this.state.commentType;
    }
    
    let user = Controller.get().userMgr().getUser();
    let data = {
      selectedEditItem: this.selectedEventInfo,
      comment: this.state.comment,
      state: this.state.commentType,
      userid: user._id,
      timestamp: new Date().toString(),
      name: user.name,
      email: user.email,
      roles: org.roles,
      managerType: org.managerType,
      tags: tags,
    };

    let editEventFunc = this.props.editEvent
    if (this.isEventDetailsPageEnabled) {
      editEventFunc = this.editEvent
    }

    editEventFunc(data, "addComment", ()=>{
      this.setState({
        seniorManagerReview: this.selectedEventInfo&& this.selectedEventInfo.latestReviewsBySeniorManager && this.selectedEventInfo.latestReviewsBySeniorManager[0] ? this.selectedEventInfo.latestReviewsBySeniorManager[0] : "",
        managerReview:  this.selectedEventInfo &&  this.selectedEventInfo.latestReviewsByManager &&  this.selectedEventInfo.latestReviewsByManager[0] ? this.selectedEventInfo.latestReviewsByManager[0]:"",
      });
    this.handleClose();
});

  }
}


editEvent = (priority, mode, callback) => {
  let options = {}
  if (mode === 'save') {
    options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
      jsonBody: {
        priority: Number(priority)
      }
    }
  } else if (mode === 'delete') {
    options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
    }
  } else if (mode === 'addComment') {
    let id = priority.selectedEditItem._id
    delete priority.selectedEditItem
    options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['camevents', id]),
      jsonBody: {
        latestComment: priority
      }
    }
  }
  else {
    return
  }
  ApiHelper.call(options, function (err, data) {
    if (err) {
      const msg = err.message ? err.message : Helper.getString('defaultErrorMsg')
      this.setState({
        errMsg: msg,
        errCode: err.respCode,
      })
      return
    }
    this.setState({
      eventEditor: false,
      errMsg: '',
      errCode: '',
    })
    window.location.reload()
  }.bind(this))
};


handleChange = (event) => {
    this.setState({
    commentType : event.target.value
    })
  };

handleCommentChange = (event) =>{
    this.setState({
    comment : event.target.value
    })
}

addNewComment  = () =>{
return (
  <div>
  {/* <div className={css(Styles.relatedImageHeaderContainer)}>
    <p className={css(Styles.relatedImageTitile)}>Related Events &nbsp;({eventsRel.length})</p>
  </div> */}
  <div className={css(Styles.relatedImageHeaderContainer)}>
<Box  sx={{ width: '80%',   flexDirection: 'column',  border: '1px solid '+AppTheme.primaryBase+' !important', marginLeft:'26px' ,paddingBottom:"2%",}}>
<Box sx={{    flexDirection: 'column' }}>

     <Radio
        className={css(Styles.reviewStatusRadio)}
        checked={this.state.commentType == "Okayed"}
        onChange={this.handleChange}
        value="Okayed"
      />{" "}
      <span className={css(Styles.textForReview)}>Okayed</span>
      {/* </Box>
<Box sx={{ width: '100%' }}> */}

      <Radio
        className={css(Styles.reviewStatusRadio)}
        checked={this.state.commentType == "Escalated"}
        onChange={this.handleChange}
        value="Escalated"
      />{" "}
      <span className={css(Styles.textForReview)}>Escalated</span>

</Box>
<Box sx={{marginLeft:"2.5%"}}>
    <textarea
        // InputProps={{ disableUnderline: true }}
        rows="5" cols="10" 
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "4px",
          resize:'none',
          width: "90%",
        }}
        value={this.state.comment}
        placeholder="Comments"
        onInput={this.handleCommentChange}
      />
</Box>
    <Box sx={{ marginLeft: "2.5%", display: "flex", justifyContent: "center", width: "90%" }}>
      <Button
        variant='contained'
        onClick={this.saveComments}
        className={css(Styles.buttonStyle)}
      >
        SAVE
      </Button>
    </Box>
    <Box sx={{ marginLeft: "2.5%", display: "flex", justifyContent: "center", width: "90%" }}>
      <p style={{ color: "#fff" }}>{this.props.commentErr}</p>
    </Box>
  </Box>

  </div>
  </div>

);}

detectChangeInEvent = () =>{
  if(this.selectedEventInfo && this.selectedEventInfo._id != this.state.selectedEditItem._id){
      this.setState({
          seniorManagerReview:this.selectedEventInfo.latestReviewsBySeniorManager[0],
          managerReview:this.selectedEventInfo.latestReviewsByManager[0],
          selectedEditItem:this.selectedEventInfo
      }, ()=>{
      })
  }
  return this.optionForComments()
}


optionForComments  = () =>{
  if (
    !this.selectedEventInfo ||
    this.selectedEventInfo.reviews.length == 0
  ) {
    return;
  }
  this.reviews = this.selectedEventInfo.reviews;
  let timeStampForSeniorManager = this.selectedEventInfo
    .latestReviewsBySeniorManager[0]
    ? this.selectedEventInfo.latestReviewsBySeniorManager[0].timestamp
    : "";
  let timeStampForManager = this.selectedEventInfo
    .latestReviewsByManager[0]
    ? this.selectedEventInfo.latestReviewsByManager[0].timestamp
    : "";
  this.reviews = [];
  this.reviews = JSON.parse(
    JSON.stringify(this.selectedEventInfo.reviews)
  );
  this.reviews.sort(function (a, b) {
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
  return this.reviews.map((element, index) => {
    return (
      <ListItem key={index}>
        <Grid container spacing={2} style={{ marginTop: "4%" }}>
          <Grid item xs={1}>
            <Radio
              checked={
                element.state == "Okayed"
                  ? element.state == "Okayed"
                  : this.state.commentType == "Okayed" && index == 0
              }
              disabled={element.state}
              value="Okayed"
            />{" "}
            Okayed
          </Grid>

          <Grid item xs={1}>
            <Radio
              checked={
                element.state == "Escalated"
                  ? element.state == "Escalated"
                  : this.state.commentType == "Escalated" && index == 0
              }
              value="Escalated"
              disabled={element.state}
            />{" "}
            Escalated
          </Grid>

          <Grid item xs={5}>
            <TextField
              id="filled-full-width"
              label="Comments"
              disabled={element.comment}
              style={{ margin: 8 }}
              value={element.comment ? element.comment : this.state.comment}
              placeholder="Comments"
              fullWidth
            />
          </Grid>

          <Grid item xs={1}>
            <TextField
              id="filled-full-width"
              disabled
              value={
                element.name
                  ? element.name
                  : element.email
              }
              label="Name "
              style={{ margin: 8 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="filled-full-width"
              disabled
              value={moment(element.timestamp).format(
                "MMM DD, YYYY, h:mm:ss A"
              )}
              label="Date"
              style={{ margin: 8 }}
            />
          </Grid>
        </Grid>
      </ListItem>
    );
  });

}

showLatestReviews = () =>{
  if((this.selectedEventInfo && this.selectedEventInfo.latestReviewsByManager && this.selectedEventInfo.latestReviewsByManager.length >0 )||(this.selectedEventInfo && this.selectedEventInfo.latestReviewsBySeniorManager&& this.selectedEventInfo.latestReviewsBySeniorManager.length >0)){
  return (
    <Box m={1} p={2}>
      <Box width="100%">
        <Card className={css(Styles.reviewCard)}>
          {this.state.seniorManagerReview && this.state.seniorManagerReview.state ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Typography variant="p" component="p">
                Org Manager Review
              </Typography>

              <IconButton
                onClick={() =>
                  this.setState({
                    openSeniorManagerReview:
                      !this.state.openSeniorManagerReview,
                  })
                }
                aria-label="show more"
              >
                <ExpandMoreIcon style={{ color: "rgb(0, 0, 0)" }} />
              </IconButton>
            </CardActions>
          ) : (
            ""
          )}
          <Divider
            style={{ backgroundColor: "rgb(0, 0, 0)", height: "1px" }}
          />
          {this.state.seniorManagerReview && this.state.seniorManagerReview.state ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Tooltip title={this.state.seniorManagerReview &&
                this.state.seniorManagerReview.name
                ? "    " + this.state.seniorManagerReview.name
                : ""}>
                <Typography noWrap >
                  {this.state.seniorManagerReview &&
                    this.state.seniorManagerReview.name
                    ? "    " + this.state.seniorManagerReview.name
                    : ""}{" "}
                </Typography></Tooltip>
              <Typography>
                {this.state.seniorManagerReview.state
                  ? "   " + this.state.seniorManagerReview.state
                  : ""}
              </Typography>
              <Tooltip title={this.state.seniorManagerReview.timestamp
                ? "    " +
                moment(this.state.seniorManagerReview.timestamp).format(
                  "MMM DD, YYYY, h:mm:ss A"
                )
                : ""}>
                <Typography noWrap >
                  {" "}
                  {this.state.seniorManagerReview.timestamp
                    ? "    " +
                    moment(this.state.seniorManagerReview.timestamp).format(
                      "MMM DD, YYYY, h:mm:ss A"
                    )
                    : ""}
                </Typography></Tooltip>
            </CardActions>
          ) : (
            ""
          )}

          {this.state.seniorManagerReview && this.state.seniorManagerReview.state ? (
            <Collapse
              in={this.state.openSeniorManagerReview}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider />
                <Typography  style={{ wordWrap: "break-word" }} paragraph>
                  {this.state.seniorManagerReview.comment
                    ? " " + this.state.seniorManagerReview.comment
                    : ""}
                </Typography>
              </CardContent>
            </Collapse>
          ) : (
            ""
          )}

          <Divider
            style={{ backgroundColor: "rgb(255, 255, 255)", height: "1px" }}
          />

          {this.state.managerReview && this.state.managerReview.state ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Typography variant="p" component="p">
                Project Manager Review
              </Typography>

              <IconButton
                onClick={() =>
                  this.setState({
                    openManagerReview: !this.state.openManagerReview,
                  })
                }
                aria-label="show more"
              >
                <ExpandMoreIcon style={{ color: "rgb(0, 0, 0)" }} />
              </IconButton>
            </CardActions>
          ) : (
            ""
          )}

          <Divider
            style={{ backgroundColor: "rgb(0, 0, 0)", height: "1px" }}
          />

          {this.state.managerReview && this.state.managerReview.state ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >  
              <Tooltip title={this.state.managerReview && this.state.managerReview.name
                  ? "    " + this.state.managerReview.name
                  : ""}>
              <Typography noWrap >
                {this.state.managerReview && this.state.managerReview.name
                  ? "    " + this.state.managerReview.name
                  : ""}{" "}
              </Typography>
              </Tooltip>
              <Typography>
                {this.state.managerReview.state
                  ? "   " + this.state.managerReview.state
                  : ""}
              </Typography>
              <Tooltip title={this.state.managerReview.timestamp
                ? "    " +
                moment(this.state.managerReview.timestamp).format(
                  "MMM DD, YYYY, h:mm:ss A"
                )
                : ""}>
                <Typography noWrap>
                  {" "}
                  {this.state.managerReview.timestamp
                    ? "    " +
                    moment(this.state.managerReview.timestamp).format(
                      "MMM DD, YYYY, h:mm:ss A"
                    )
                    : ""}
                </Typography></Tooltip>
            </CardActions>
          ) : (
            ""
          )}

          {this.state.managerReview && this.state.managerReview.state ? (
            <Collapse
              in={this.state.openManagerReview}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider />
                <Typography  style={{ wordWrap: "break-word" }} paragraph>
                  {this.state.managerReview.comment
                    ? " " + this.state.managerReview.comment
                    : ""}
                </Typography>
              </CardContent>
            </Collapse>
          ) : (
            ""
          )}
        </Card>
      </Box>
    </Box>
  );
}
}
  openCommentsModal(){
  return (
    <div>
      <Dialog fullScreen open={this.state.openCommentsModal || false} onClose={this.handleClose} TransitionComponent={Transition}>
        <AppBar >
          <Toolbar style={{justifyContent: "space-between"}}>
            <Typography variant="h6">
              Comments
            </Typography>
                <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
        {this.detectChangeInEvent()}
          <Divider />
        </List>
      </Dialog>
    </div>
  );

}

  renderEventControls = (item) => {
    if (!item) return

    return (
      <div className={this.props.relativeImageSliderMode ? css(Styles.eventControlContentRelativeOpen) : css(Styles.eventControlContent)} >
        <div className={css(Styles.eventInnerControlContent)}>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === 0 ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.eventBefore(item)}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                skip_previous
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Previous Event</p>
          </div>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === (this.props.eventList.length - 1) ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.onPlay()}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                {this.state.player}
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Slideshow</p>
          </div>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === (this.props.eventList.length - 1) ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.eventNext()}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                skip_next
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Next Event</p>
          </div>
        </div>
      </div>
    )
  }

  renderEventEditContainer = (item) => {
    if (!item) return

    return (
      <div className={css(Styles.eventEditOuterContainer)}>
        <div className={css(Styles.eventEditContainer)}>
          {Controller.get().userMgr().isSuperAdminUser() &&
            <Tooltip title={'EDIT EVENT'}>
              <div className={css(Styles.eventEditImgContainer)}>
                <div className={css(Styles.eventEditImg)} onClick={() => this.props.showEventEditor(item)}>
                  <img src={require('../../assets/images/edit-img.svg')} />
                </div>
              </div>
            </Tooltip>
          }
               
          {/* {
            <Tooltip title={'GEN AI EVENT'}>
              <div className={css(Styles.eventEditImgContainer)}>
                <div className={css(Styles.eventEditImg)} onClick={() => this.props.applyGenAI(item)}>
                  GEN AI
                 </div>
              </div>
            </Tooltip>
          } */}
          {!this.props.independentEventDetails && this.props.selectedEvent && this.props.selectedEvent._id &&
            <div style={{ display: "flex" }}>
              {/* <Tooltip title={'EVENT DETAILS'}>
                <Link
                  to={{
                    pathname: '/events/' + this.props.selectedEvent._id,
                  }}
                  target={'_blank'}
                  className={css(Styles.link)}>
                  <div className={css(Styles.eventEditImgContainer)}>
                    <div className={css(Styles.eventEditImg)}>
                      <Icon
                        className={css(Styles.openEventIcon)}>
                        open_in_new
                      </Icon>
                    </div>
                  </div>
                </Link>
              </Tooltip> */}
              {/* {AppConfig.allowEventReviews && AppConfig.SHOW_VIOLATION ?
                <Tooltip title={'COMMENT DETAILS'}>
                  <div className={css(Styles.eventEditImgContainer)}>
                    <div className={css(Styles.eventEditImg)}>
                      <Icon onClick={() => this.handleClickOpen()}
                        className={css(Styles.openEventIcon)}>
                        add_comment
                      </Icon>
                    </div>
                  </div>
                </Tooltip> : ""} */}
            </div>
          }

          {this.state.fetchStateForFR == ApiHelper.State.LOADING ?
             <div className={css(Styles.eventEditImgContainer)}>
             {/* <div className={css(Styles.eventEditImg)} > */}
                <CircularProgress color='#000' size={15} className={css(Styles.progress)} />
              {/* </div>  */}
              </div>
            :
            this.props.enableFrButton ?<Tooltip title={'ADD USER'}>
              <div className={css(Styles.eventEditImgContainer)}>
                <div className={css(Styles.eventEditImg)} onClick={() => this.showAddUserNotificationDIalog()}>
                  <img src={require('../../assets/images/person-add.svg')} />
                </div>
              </div>
            </Tooltip> : <></>

          }
        </div>
        {
          this.props.liveUrl && this.props.camId &&
          <div className={css(Styles.eventLiveFeedContainer)}>
            <Link
              to={{
                pathname: '/places/' + this.props.placeId + '/cams/' + this.props.camId,
                state: {
                  eventId: this.props.independentEventDetails && this.props.selectedEvent && this.props.selectedEvent._id
                } // To decide back path name
              }}
              className={css(Styles.link)}>
              <div className={css(Styles.eventLiveFeedContent)}>
                <p className={css(CommonStyles.margin)}>VIEW LIVE FEED</p></div>
            </Link>
          </div>
        }
      </div >
    )
  }

  VideoPlayer = (item) => {

    const handleError = (e) => {
      console.error('Error playing video:', e);
      this.setState({
        videoError: true,

      })
    };
    const handleReady = (e) => {
      this.setState({
        isVideoReady: true,
      })
    };

    // if (!item || !item.streamUrls || !item.streamUrls.mp4Https || !this.props.eecmToken ) return;
    let videoUrl = item.streamUrls.mp4Https + (this.props.intType == "eevms" ? "" : '?strict=false&access_token=' + this.props.eecmToken);
    // if(this.props.eecmToken){
    //   videoUrl +=  '?strict=false&access_token=' + this.props.eecmToken ;
    // }
    console.log("-----", videoUrl)
    return (
      <>
        <div style={{ display: this.state.isVideoReady ? 'block' : 'none' }}>
          {<ReactPlayer
            url={videoUrl}
            // playing={true}
            controls={true}
            width="86%"
            height="auto"
            onError={handleError}
            onReady={handleReady}

          />}

        </div>
        {!this.state.isVideoReady && !this.state.videoError && <p className={css(Styles.eventInnerControlImgTitle)}>Loading video...</p>}
        {!this.state.isVideoReady && this.state.videoError && <p className={css(Styles.eventInnerControlImgTitle)}>There was a problem fetching the video</p>}</>
    );
  };

  renderEvenImageSliderMini = (item) => {
    if (!item) return
    const eventImages = this.processImages(item)
    if (eventImages && eventImages.length > 0) {
      let setting = {}
      let relativeLess = false
      let marginLeftRequired = false
      if (eventImages.length < 3) {
        marginLeftRequired = true
      }
      if (eventImages.length < 2) {
        setting = relativesettingsLess
        relativeLess = true
      } else {
        setting = relativesettings
      }
      return (
        <div >
          <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)} onClick={() => {
              this.setState({
                openRelatedImages: !this.state.openRelatedImages
              })
            }}>Related Images &nbsp;({eventImages.length})</p>
            <IconButton className={css(Styles.pl0)}
              onClick={() =>
                this.setState({
                  openRelatedImages:
                    !this.state.openRelatedImages,
                })
              }
              aria-label="show more"
            >
              {this.state.openRelatedImages ? (
                <ExpandMoreIcon style={{ color: "rgb(0, 0, 0)" }} />
              ) : (
                <ChevronRightIcon style={{ color: "rgb(0, 0, 0)" }} />
              )}            
            </IconButton>
          </div>

          <Collapse
              in={this.state.openRelatedImages}
              timeout="auto"
              unmountOnExit
            >
              <CardContent style={{padding:0}}>
                {
            this.props.independentEventDetails  &&
            <Grid container spacing={2} className={css(Styles.gridImageContainer)}  >
              {eventImages.length > 0   &&
                eventImages.map((imageUrl, index) => {
                  return (
                    <Grid key={index} item xs={6} sm={6}>
                      <div
                        className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                        onClick={() => this.showRelatedImages(index)}>
                        <div className={(this.state.selectedImageIndex === index) ?
                          css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                          <img style={{imageRendering: 'pixelated'}}
                            src={imageUrl}
                            key={imageUrl}
                            onError={(e) => {
                              e.target.src = require('../../assets/images/error2.svg')
                            }}
                            className={css(Styles.relativeImageContent)} />
                        </div>
                      </div>
                    </Grid>
                  )
                })

              }
            </Grid>
          }
            


          {!this.props.independentEventDetails &&
            <div>
              <Slider {...setting}
                className={marginLeftRequired ? css(Styles.relativeSettingsWithMargin) : css(Styles.relativeSettings)}>
                {eventImages.length > 0 &&
                  eventImages.map((imageUrl, index) => {
                    return (
                      <div
                        key={index}
                        className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                        onClick={() => this.showRelatedImages(index)}>
                        <div className={(this.state.selectedImageIndex === index) ?
                          css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                          <img
                            src={imageUrl}
                            key={imageUrl}
                            onError={(e) => {
                              e.target.src = require('../../assets/images/error2.svg')
                            }}
                            className={css(Styles.relativeImageContent)} />
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          }
            </CardContent>
            </Collapse>
        </div>
      )
    }
    return ''
  }

  renderEvenVideoSliderMini = (item) => {
    if (!item || !AppConfig.SHOW_VIDEO ) return
    if (!item || !item.streamUrls || !item.streamUrls.mp4Https || (!this.props.eecmToken && this.props.intType != "eevms" )) return (
      <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)}>Fetching video. Please wait</p>
      </div>
    )

    const eventImages = this.processImages(item)
    if (eventImages && eventImages.length > 0) {
      return (
        <div>
          <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)}>Related Video </p>
          </div>
          <div className={css(Styles.relatedImageTitile, Styles.videoHeightContent)}>
            {this.VideoPlayer(item)}

         {/* {!this.state.showVideo &&   <Tooltip title={'SHOW VIDEO'}>
              <div className={css(Styles.eventEditImgContainer)}>
                <div className={css(Styles.eventEditImg)} onClick={() =>this.setState({
                  showVideo:true
                })}>
                  SHOW VIDEO
                </div>
              </div>
            </Tooltip>} */}
          </div>
        </div>
      )
    }
    return ''
  }


  renderEventSliderMini = (item) => {
    if (!item) return

    let eventsRel = [item]

    if (item.metadata && item.metadata.groupId &&this.props.eventsByGroupId[item.metadata.groupId] &&
      this.props.eventsByGroupId[item.metadata.groupId].length > 0) {

      eventsRel = eventsRel.concat(this.props.eventsByGroupId[item.metadata.groupId])

      let setting = {}
      let relativeLess = false

      if (eventsRel.length < 2) {
        setting = relativesettingsLess
        relativeLess = true
      } else {
        setting = relativesettings
      }

      let marginLeftRequired = false
      if (eventsRel.length < 3) {
        marginLeftRequired = true
      }

      return (
        <div>
          <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)}>Related Events &nbsp;({eventsRel.length})</p>
          </div>
          <Slider {...setting}
            className={marginLeftRequired ? css(Styles.relativeSettingsWithMargin) : css(Styles.relativeSettings)}>
            {eventsRel.map((option, index) => {
              return (
                <div
                  key={index}
                  className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                  onClick={() => this.showRelatedEvents(index, option)}>
                  <div
                    className={(this.state.selectedRelatedEventIndex === (index)) ?
                      css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                    <img
                      src={option.image}
                      key={option.image}
                      onError={(e) => {
                        e.target.src = require('../../assets/images/error2.svg')
                      }}
                      className={css(Styles.relativeImageContent)}></img>
                    <div className={css(Styles.eventListTitleConatiner, Styles.eventListTitleConatinerCust)}>
                      <p className={css(Styles.eventListTitle, Styles.eventListTitleCust)}>{option.name}</p>
                      <div className={css(Styles.eventListDateContainer)}>
                        <p className={css(Styles.eventListDate, Styles.eventListDateCust)}>
                          {moment(option.event_time).format('MMM DD, YYYY, h:mm:ss A')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      )
    }
    return ''
  }

  getEventItemHeaderContainerStyle = () => {
    if (this.props.independentEventDetails) {
      return css(Styles.eventItemHeaderContainer, Styles.eventItemHeaderContainerSpec)
    } else {
      return css(Styles.eventItemHeaderContainer)
    }
  }

  getEventItemOuterContainerStyle = () => {
    if (this.props.independentEventDetails) {
      return css(Styles.eventItemOuterContainer, Styles.eventItemOuterContainerSpec)
    } else {
      return css(Styles.eventItemOuterContainer)
    }
  }

  roundRect = (
    ctx,
    x,
    y,
    width,
    height,
    radius = 5,
    title,
    fill = false,
    stroke = true
  ) => {
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }

    ctx.beginPath();
    ctx.moveTo(x + width - radius.tr, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y + radius.tr);

    ctx.moveTo(x + width, y + height - radius.br);
    ctx.lineTo(x + width, y + height);
    ctx.lineTo( x + width - radius.br, y + height);

    ctx.moveTo(x + radius.bl, y + height);
    ctx.lineTo(x, y + height);
    ctx.lineTo(x, y + height - radius.bl);

    ctx.moveTo(x, y + radius.tl);
    ctx.lineTo(x, y);
    ctx.lineTo( x + radius.tl, y);
    ctx.moveTo(x, y + radius.tl);

    ctx.closePath();
    ctx.font="15px kayak";
    ctx.shadowColor="white";
    ctx.strokeText(title? title.toLowerCase() :"", x , y-(20/2));
    ctx.shadowBlur=0;
    ctx.fillStyle = 'rgb(125,19,124)';
    ctx.fillText(title? title.toLowerCase() :"", x , y-(20/2));
    
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  onImgLoad = (e) => {
    if(!AppConfig.allowBoundingBox){
      return
    }
    const image = document.getElementById('source');
    var canvas = this.canvas.current
    const ctx = canvas.getContext("2d")
    const img = this.imageRef.current
    let bbox = JSON.parse(img.attributes.bbox.nodeValue)
    if(!bbox || bbox.length == 0 ){
      return
    }
    this.setState({
      showCanvas: true,
      imageInfo: e.target.src,
      eventInfoImgeHeight: e.target.clientHeight,
      eventInfoImgeWidth: e.target.clientWidth,
    }, () => {

      ctx.drawImage(image, 0, 0, this.state.eventInfoImgeWidth, this.state.eventInfoImgeHeight)
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.strokeStyle = "white";
      let scale_o = image.naturalWidth / canvas.width
      for (let index = 0; index < bbox.length; index++) {
        let element = bbox[index];
        element.x = (parseFloat(element.xmin) / scale_o )
        element.y = (parseFloat(element.ymin) / scale_o )
        element.width =( parseFloat(element.xmax-element.xmin) / scale_o )
        element.height = (parseFloat(element.ymax-element.ymin) / scale_o )
        if(element.blur){
        ctx.filter = 'blur(' + 10 + 'px)';
        }else{
          ctx.filter = ""
        }
        ctx.drawImage(canvas,
          element.x, element.y, element.width, element.height,
          element.x, element.y, element.width, element.height
        );
        ctx.filter = 'none'; 
        ctx.fillStyle = 'rgba(255,255,255,0.2)';
        
        this.roundRect(ctx, element.x, element.y, element.width, element.height, 15, element.title)
        // ctx.rect( element.x, element.y, element.width, element.height, 18, element.title)

      }

      ctx.stroke();

    })
  }

  componentWillReceiveProps (newProps) {
    if(newProps && this.props && newProps.selectedEvent && this.props.selectedEvent && this.props.selectedEvent._id && newProps.selectedEvent._id &&  newProps.selectedEvent._id !== this.props.selectedEvent._id ) /* do stuff */{
      this.initializeCanvas()
      this.setState({
        videoError:false,
        showVideo:false,
        isVideoReady:false
      })
    }
    
  }

  componentWillMount(){
    // this.fetchNotificationUsers()
  }

  initializeCanvas = () =>{
    this.setState({
      showCanvas:false,
      imageInfo:"",
      eventInfoImgeHeight : 0,
      eventInfoImgeWidth : 0,
    })
  }

  // updateFaceTag= (item)=>{
  //   if(item && item.tags && item.tags.faces && item.tags.faces.length > 0 && Object.keys(faceMap).length > 0){
  //     for (let index = 0; index < item.tags.faces.length; index++) {
  //       let faceId = item.tags.faces[index];
  //       let name = faceMap[faceId] 
  //       if(name){
  //         item.tags["name-"+(index+1)] = name
  //       }else{
  //         item.tags["name-"+(index+1)] = "Deleted User"    
  //       }
  //     }
  //     // delete tags.faces
  //   }
  // }

  renderEventInfo = (item) => {
    let itemToRender = item
    // if(item && item.tags && item.tags.faces && item.tags.faces.length > 0  && JSON.stringify(Object.keys(item.tags)).includes('name') == false ){
    //   this.updateFaceTag(itemToRender)
    // }
    let imageUrl = item ? item.image : null
    if (this.props.selectedRelatedEvent) {
      itemToRender = this.props.selectedRelatedEvent
      imageUrl = this.props.selectedRelatedEvent.image
    }

    return (
      <div className={this.getEventItemOuterContainerStyle()}>
        <div className={this.getEventItemHeaderContainerStyle()}>
          {this.renderEventHeader(itemToRender)}

          {this.props.relativeImageSliderMode && (
            <div>
              <div>{this.renderRelativeImageSlider(itemToRender)}</div>
            </div>
          )}

          {!this.props.relativeImageSliderMode && imageUrl && (
            <div
              className={css(Styles.eventImageContent)}>
              {AppConfig.allowBoundingBox && <canvas ref={this.canvas} width={this.state.eventInfoImgeWidth} height={this.state.eventInfoImgeHeight} className={css(Styles.eventImage)} />
              }
              {this.state.showCanvas == false &&
                <img className={css(Styles.eventImage)} id='source' ref={this.imageRef}
                  onLoad={this.onImgLoad}
                  bbox={itemToRender.bbox ? JSON.stringify(itemToRender.bbox) : JSON.stringify([])}
                  key={imageUrl}
                  src={imageUrl}
                  onError={(e) => {
                    e.target.src = require("../../assets/images/error2.svg");
                  }}
                />
              }
            </div>
          )}

          {!this.props.independentEventDetails &&
            this.renderEventControls(item)}
        </div>

        <div
          className={
            this.props.relativeImageSliderMode
              ? css(Styles.eventItemFeatureCoontainerRelativeOpen)
              : css(Styles.eventItemFeatureContainer)
          }
        >
          {this.renderEventEditContainer(itemToRender)}

          {this.renderEvenImageSliderMini(itemToRender)}

          {this.renderEvenVideoSliderMini(itemToRender)}


          {this.renderEventSliderMini(item)}

          {AppConfig.allowEventReviews && AppConfig.SHOW_VIOLATION ? this.openCommentsModal() : ""}

          {AppConfig.allowEventReviews  && AppConfig.SHOW_VIOLATION  ? this.showLatestReviews() : ""}

          {AppConfig.allowEventReviews  && AppConfig.SHOW_VIOLATION  ? this.addNewComment() : ""}
        </div>
     
     
        {
        /* {this.state.cam.type == 'alert' && this.state.cam.intType && this.state.cam.intType == 'eecm' && this.state.showOffice &&
          */
        this.renderUserNotificationDialog()
         }
      </div>
    );
  }

  render = () => {
    this.selectedEventInfo = this.props.selectedEditItem
    this.isEventDetailsPageEnabled = false	    
    if(!this.props.selectedEditItem && this.props.selectedEvent){	
      this.selectedEventInfo = this.props.selectedEvent	
      this.isEventDetailsPageEnabled = true	
    }	   
    return this.renderEventInfo(this.props.selectedEvent)
  }
}