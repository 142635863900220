/* eslint-disable */ 
import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { ColourPalette } from '../styles/AppColourPalette';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import {
  StyleSheet,
  css
} from 'aphrodite';
import { AppHelper } from '../helpers/AppHelper';

export class CheckboxComponent extends Component {

  constructor(props) {
    super(props);
    this.initialValue = this.props.value
    this.selectAll = false
    this.selectAllOption = AppHelper.getSelectAllOption()
    this.state = {
    }
  }

  componentDidMount() {
    // this.isAllSelected()
  }

  isAllSelected = () => {
    const values = this.props.value
    const selectAllKey = this.selectAllOption.key
    if ((values.length === this.props.formField.options.length - 1) &&
      !values.includes(selectAllKey) &&
      !this.props.disableSelectAll) {
      values.push(selectAllKey)
      this.selectAll = true
      this.setValue(values,doUpdate); //NOTE: If total number of options equals the selected values then select all option will be checked
    }
  }

  setValue(value) {
    this.props.setValue(value, this.props.formField.key)
  }

  change = (e, item) => {
    const selectAllKey = this.selectAllOption.key
    if (item.key === selectAllKey || item.props.value == selectAllKey) {
      // this.selectAll = !this.selectAll
      this.selectAllValues() //NOTE:If Values includes select all key. triggers when we click on select all
    } else {
      if ((e.target.value.length === this.props.formField.options.length - 1) &&
        !e.target.value.includes(selectAllKey) &&
        !this.props.disableSelectAll) {
        e.target.value.push(selectAllKey)
        this.setValue(e.target.value); //NOTE: If total number of options equals the selected values then select all option will be checked
      } else {
        if (e.target.value.includes(selectAllKey)) {
          e.target.value = e.target.value.filter(function (value) {
            return value !== selectAllKey
          })
        }
        // this.selectAll = !this.selectAll
        this.setValue(e.target.value)
      }
    }
  }

  selectAllValues = () => {
    const value = []
    if (this.selectAll) {
      for (const f of this.props.formField.options) {
        if (f.key !== 'selectAll') {
          value.push(f.key)
        }
      }
      value.push(this.selectAllOption.key)
      this.setValue(value)
    } else {
      this.setValue(value)
    }
  }

  render() {
    const matchesFound = this.props.formField && this.props.formField.options.some(ai => this.props.value.includes(ai.key))
    return (
      <FormControl
        style={this.props.inputCss}
        className={this.props.headerView ? css(Styles.multiPickerForm) : css(Styles.inputCss)}>
        {this.props.formField.label &&
          <InputLabel
            className={css(Styles.inputLabel)}
            classes={{
              shrink: css(Styles.inputLabelShrink)
            }}>
            {this.props.formField.label}
          </InputLabel>
        }
        <Select
          multiple 
          value={matchesFound ? this.props.value : []}
          onChange={(e, item) =>
            {
              if(e.target.value.includes('selectAll')){
                this.selectAll = true;
              }else{
                this.selectAll = false
              }
            this.change(e, item)
          }
          
          }
          className={this.props.headerView ? css(Styles.pickerInnerContainer) : css(Styles.dummyClass)}
          renderValue={selected => selected.join(', ')}
          renderValue={selected => {
            return (
              <div style={{ 
                maxWidth: this.props.headerView ? 135 : 'unset', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
                }}>
                {
                  this.props.formField.options.map(item => {
                    return (
                      selected.map((key, index) => {
                        if (key !== 'selectAll') {
                          if (selected.length - 1 === index) {
                            if (selected.length === 1) {
                              return (
                                key === item.key &&
                                [this && this.props &&  item.name_wfh ? item.name_wfh :item.name]
                              )
                            } else {
                              return (
                                key === item.key &&
                                [this && this.props &&  item.name_wfh ? item.name_wfh :item.name] + ','
                              )
                            }
                          } else {
                            return (
                              key === item.key &&
                                [this && this.props &&  item.name_wfh ? item.name_wfh :item.name] + ', '
                            )
                          }
                        }
                      }))
                  })
                }
              </div>
            )
          }}
          disabled={this.props.disabled}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            classes: {
              paper: css(CommonStyles.menuItemContainerRoot),
              list: css(CommonStyles.menuItemListContainerRoot)
            }
          }}
          input={
            <Input
              disableUnderline={true}
            />
          }
          classes={{
            selectMenu: this.props.headerView ? css(Styles.selectOrgMenu) : this.props.action === 'viewInfo' ? css(Styles.selectMenu, Styles.textFieldContentInputView) : css(Styles.selectMenu),
            icon: this.props.headerView ? css(Styles.selectIcon) : this.props.action === 'viewInfo' ? css(Styles.selectIconView) : css(Styles.selectIcon2),
          }}
        >
          {this.props.formField.options.map(item => {
            return (
              <MenuItem
                key={item.key}
                value={item.key}
                className={css(CommonStyles.menuItemContainer)}>
                <Checkbox
                  checked={this.props.value.indexOf(item.key) > -1}
                  className={css(Styles.selectCheckbox)} />
                <ListItemText primary={this && this.props && this.props.orgType && this.props.orgType == "wfhTeams" &&  item.name_wfh ? item.name_wfh : item.name} />
              </MenuItem>
            )
          })}
        </Select>

        {
          !this.props.headerView &&
          <FormHelperText
            className={css(Styles.errorMsgStyle)}>
            {!this.props.enableValidation ? '' : ValidationHelper.validateFields(this.props.formField, this.props.value)}
          </FormHelperText>
        }

      </FormControl >
    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    color:"#000",
    // color: AppTheme.white,
    minHeight: AppTheme.formFieldHeight,
    '@media(max-width: 767px)': {
      maxWidth: window.innerWidth / 2
    },
    fontFamily:"Roboto,sans-serif !important",

  },
  inputLabel: {
    // color: AppTheme.white,
    marginLeft: 8,
    opacity: 1,
    zIndex: 1,
    pointerEvents: 'none',
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  inputLabelShrink: {
    right: 'unset',
    color:"#000",
  },
  selectMenu: {
    // backgroundColor: AppTheme.secondaryColor,
    backgroundColor:"#eeeeee",
    // color: AppTheme.white,
    color:"#000",
    paddingLeft: 8,
    borderRadius: 4,
    border:"0.2px solid"
  },
  textFieldContentInputView: {
    // backgroundColor: AppTheme.primaryBackground,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
  },
  selectIcon: {
    // color: AppTheme.white,
    color:"#fff",
    // backgroundColor: AppTheme.secondaryColor,
    marginRight: 1,
  },
  selectIcon2: {
    // color: AppTheme.white,
    color:"#000",
    // backgroundColor: AppTheme.secondaryColor,
    marginRight: 1,
  },
  selectIconView: {
    // color: AppTheme.primaryBackground
    color: "#fff"

  },
  selectCheckbox: {
    // color: AppTheme.white
    color:AppTheme.primaryBase
  },
  errorMsgStyle: {
    color: AppTheme.white,
    paddingLeft: 8
  },
  multiPickerForm: {
    '@media(max-width: 768px)': {
      flex: 1
    }
  },
  dummyClass: {
  },
  pickerInnerContainer: {
    color: AppTheme.white,
    marginRight: 10,
    '@media (max-width: 768px)': {
      marginRight: 0,
    }
  },
  selectOrgMenu: {
    minWidth: 150,
    maxWidth: 150,
    // backgroundColor: AppTheme.secondaryColor,
    boxShadow:"rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px !important",
    color: AppTheme.white,
    fontSize: 15,
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    // border: '1px solid ' + AppTheme.secondaryColor,
    ':hover': {
      // border: '1px solid ' + ColourPalette.color3
    },
    '@media(max-width: 768px)': {
      minWidth: 'unset'
    }
  },
})